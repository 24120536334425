<template>
  <li v-if="(commission.total && type == 'other') || type != 'other'">
    <div class="d-flex">
      <h3>{{ commission.title }}</h3>
      <template v-if="type == 'permium'">
        <a
          class="action-icon"
          href="javascript:void(0)"
          v-b-tooltip.hover
          title="Download PDF"
          @click="downloadReport(commission.pdf)"
        >
          <i class="fas fa-file-pdf"></i>
        </a>
        <a
          class="action-icon"
          href="javascript:void(0)"
          v-b-tooltip.hover
          title="Download CSV"
          @click="downloadReport(commission.csv)"
        >
          <i class="fas fa-file-csv csv" />
        </a>
      </template>
    </div>
    <ul class="commission-status">
      <template v-if="commission.sub_comm">
        <li class="paid2" v-for="(d, i) in commission.sub_comm" :key="i">
          <template v-if="d.amount">
            {{ d.title }}
            <strong> ${{ d.amount }} </strong>
            <template
              v-if="
                parseFloat(d.amount.replace(/,/g, '')) > 0 && type == 'other'
              "
            >
              <a
                class="action-icon"
                href="javascript:void(0)"
                v-b-tooltip.hover
                title="Download PDF"
                @click="downloadReport(d.pdf)"
              >
                <i class="fas fa-file-pdf"></i>
              </a>
              <a
                class="action-icon"
                href="javascript:void(0)"
                v-b-tooltip.hover
                title="Download CSV"
                @click="downloadReport(d.csv)"
              >
                <i class="fas fa-file-csv csv" />
              </a>
            </template>
          </template>
        </li>
      </template>

      <li class="cs-sub-title">
        <strong v-if="commission.total">
          Total:&ensp;<span class="paid"> ${{ commission.total }} </span>
        </strong>
        <ul>
          <li class="paid">
            <strong>Paid: </strong>
            ${{ commission.paid }}
          </li>
          <li class="unpaid">
            <strong>Unpaid: </strong>
            ${{ commission.unpaid }}
          </li>
          <li class="process">
            <strong>Process: </strong>
            ${{ commission.process }}
          </li>
        </ul>
      </li>
    </ul>
  </li>
</template>
<script>
export default {
  name: "CommissionStatusBlock",
  props: {
    commission: {
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    downloadReport(fileName) {
      let url =
        this.$endpoint.PURENROLL_API_URL + `downloadStatement/${fileName}`;
      window.open(url, "_blank");
    },
  },
};
</script>
<style >
</style>
