<template>
  <div class="page-wrapper commissions-wrap">
    <div class="container-fluid">
      <commission-projected @onRepSelect="getAll" />
      <div class="page-title">
        <h1 class="pt-4">Monthly Commission Report</h1>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="new-table-card">
            <div class="d-flex justify-content-between align-items-center top-pagination">
              <page-result :rows.sync="rows"/>
              <pagination :rows.sync="rows"  />
            </div>
            <simple-table :columns="columns">
              <template v-slot:tableBody>
                <commission-table :rows.sync="rows" />
              </template>
            </simple-table>
            <div class="">
              <div class="d-flex justify-content-between align-items-center ">
                <page-result :rows.sync="rows" />
                <pagination :rows.sync="rows" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Api from "@/resource/Api";
import CommissionProjected from "./CommissionProjected.vue";
import CommissionTable from "./partials/CommissionTable.vue";
export default {
  name: "CommissionList",
  components: { CommissionTable, CommissionProjected },
  data: () => ({
    rows: [],
    columns: [
      { label: "Month Year#" },
      { label: "Direct Comm." },
      { label: "Downline Comm." },
      { label: "Total Comm." },
      { label: "Total Commissions Paid" },
      { label: "Status" },
      { label: "Actions" },
    ],
    repId: "",
  }),
  methods: {
    getAll(repId) {
      this.repId = repId;
      let loader = this.$loading.show();
      const url =
        repId == null || repId == ""
          ? "v2/commission/get"
          : `v2/commission/get?agent_id=${repId}`;
      Api.get(url)
        .then((res) => {
          this.rows = res.data;
        })
        .catch(() => {
          this.rows = [];
        })
        .finally(() => {
          loader.hide();
        });
    },
  },
  created() {
    this.getAll();
    this.getDownlineReps();
  },
  watch: {
    rows(value) {
      if (value) {
        this.rows.path += `?agent_id=${this.repId}`;
      }
    },
  },
};
</script>
<style src="@/assets/css/commissions.css"></style>
<style scoped>
.top-pagination {
  margin-bottom: 20px;
}
</style>
