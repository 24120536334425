<template>
  <div class="col-12">
    <breadcrumb :items="breadcrumbItems" />
    <div class="commissionProjected-wrap">
      <h2>Commission For : {{ currentCommissionDate }}</h2>
      <div class="hcp-filter d-flex flex-wrap">
        <input-multi-select
          :vmodel.sync="downlineAgentId"
          :options="downlineReps.map((type) => type.id)"
          :custom-label="
            (opt) =>
              downlineReps.find((x) => x.id == opt).first_name +
              ' ' +
              downlineReps.find((x) => x.id == opt).last_name +
              '(' +
              downlineReps.find((x) => x.id == opt).agent_code +
              ')'
          "
          formGroupClass="downline-rep-select me-2 "
          placeholder="Select a Downline Rep"
          @onSearchChange="getDownlineReps"
          @onInput="getRepCommissions"
          :showLabel="false"
        />
        <form @submit.prevent="filterCommision" class="d-flex flex-wrap">
          <select class="select-month" v-model="month">
            <option v-for="m in months" :key="m.value" :value="m.value">
              {{ m.text }}
            </option>
          </select>
          <select
            class="select-year"
            v-model="year"
            @change.prevent="updateYear"
          >
            <option v-for="y in years" :key="y" :value="y">
              {{ y }}
            </option>
          </select>
          <button type="submit" class="btn btn-primary search-commission me-2">
            Search
          </button>
          <button class="clear-search h-25" @click="clearFilter">Clear</button>
        </form>
      </div>
      <div class="row mb-4 graphic-div">
        <div class="col-xxl-3 col-xl-4 col-md-5 mb-3">
          <div class="commission-donutChart pt-2">
            <apexchart
              type="donut"
              height="300px"
              :options="options"
              :series="series"
            ></apexchart>
          </div>
        </div>
        <div class="col-xxl-9 col-xl-8 col-md-7">
          <div class="valueDenote pb-2 ps-3">
            <ul>
              <li><span class="value-active"></span>Paid</li>
              <li><span class="value-termed"></span>Unpaid</li>
              <li><span class="value-withdrawn"></span>Process</li>
            </ul>
          </div>
          <div class="commission-details">
            <ul>
              <commission-status-block
                :commission.sync="premiumCommission"
                class="premium-li"
                type="permium"
              />
              <commission-status-block
                :commission.sync="otherCommission"
                class="other-li"
                type="other"
              />
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/
import Api from "@/resource/Api";
import moment from "moment";
import Helper from "@/resource/Helper";
import CommissionStatusBlock from "./partials/CommissionStatusBlock.vue";
export default {
  name: "CommissionProjected",
  components: { CommissionStatusBlock },
  data: () => ({
    options: {},
    series: [],
    month: "",
    year: "",
    premiumCommission: {},
    otherCommission: {},
    months: [],
    years: [],
    currentCommissionDate: "",
    breadcrumbItems: [{ title: "Commission", active: true }],
    downlineReps: [],
    downlineAgentId: "",
  }),
  methods: {
    getRepCommissions() {
      this.$emit("onRepSelect", this.downlineAgentId);
    },
    async clearFilter() {
      await this.getDates();
      this.downlineAgentId = "";
      this.$emit("onRepSelect", this.downlineAgentId);
    },
    getDownlineReps(rep) {
      let url = `v2/get-rep-downline${
        rep ? `?rep_name=${rep}&size=20` : "?size=20"
      }`;
      Api.get(url)
        .then((res) => {
          this.downlineReps = res.data.data;
        })
        .catch((err) => {
          console.log("FAiled to get downline rep", err);
        });
    },
    setCommissionDate(year, month) {
      this.currentCommissionDate =
        moment(month, "M").format("MMMM") + " " + year;
    },
    updateYear() {
      let month = parseInt(
        moment().subtract("10", "days").subtract(1, "months").format("M")
      );
      if (this.year == moment().year()) {
        this.months = this.months.filter((el) => {
          if (el.value <= month) {
            return el;
          }
        });
        if (this.month > month) {
          this.month = month;
        }
      } else {
        this.months = Helper.getMonths();
      }
    },
    getDates() {
      let currentYear = moment().year(),
        years = [],
        startYear = 2019;
      while (startYear <= currentYear) {
        years.push(startYear++);
      }

      this.year = currentYear;
      this.month = parseInt(
        moment().subtract("9", "days").subtract(1, "months").format("M")
      );
      if (this.month > moment().format("M")) {
        this.year--;
      }

      // console.log(
      //   "month2",
      //   moment("2021-09-10")
      //     .subtract("10", "days")
      //     .subtract(1, "months")
      //     .format("M")
      // );

      this.months = Helper.getMonths();
      this.years = years;
      this.updateYear();
    },
    filterCommision() {
      this.getCommission(this.month, this.year);
    },
    getCommission(month, year) {
      const [labels, colors] = [
        ["Paid", "Unpaid", "Process"],
        ["#02BC77", "#EA584E", "#F29423"],
      ];
      let loader = this.$loading.show();
      let mainUrl = `v2/commission/stat-by-month?year=${year}&month=${month}`;
      let url =
        this.downlineAgentId == null || this.downlineAgentId == ""
          ? mainUrl
          : `${mainUrl}&agent_id=${this.downlineAgentId}`;
      Api.get(url)
        .then((res) => {
          let data = res.data.data;
          this.premiumCommission = data.premium ? data.premium : {};
          this.otherCommission = data.other ? data.other : {};
          let overall = data.overall;
          this.series = [
            parseFloat(overall.paid) ?? 0,
            parseFloat(overall.unpaid) ?? 0,
            parseFloat(overall.process) ?? 0,
          ];
          this.options = this.chartOptions(labels, colors, overall.total ?? 0);
        })
        .catch(() => {
          this.premiumCommission = {};
          this.otherCommission = {};
          this.series = [0, 0, 0];
          this.options = this.chartOptions(labels, colors, 0);
        })
        .finally(() => {
          this.setCommissionDate(year, month);
          loader.hide();
        });
    },
    chartOptions(labels, colors, total) {
      const options = {
        labels: labels,
        plotOptions: {
          pie: {
            donut: {
              size: "40%",
            },
          },
        },
        legend: {
          show: false,
        },
        dropShadow: {
          enable: false,
        },
        colors: colors,
        stroke: {
          colors: "#F3F7FA ",
        },
        dataLabels: {
          dropShadow: {
            enabled: false,
          },
          style: {
            fontSize: "10px",
          },
        },
        tooltip: {
          x: {
            show: true,
          },
          y: {
            formatter: function (value) {
              let percent = (value / total) * 100;
              percent = percent.toFixed(2) + "%";
              if (value % 1 == 0) {
                value = `$${parseInt(value)}.00`;
              } else {
                value = `$${value}`;
              }
              return `${value}(${percent})`;
            },
          },
        },
      };
      return options;
    },
  },
  created() {
    this.getDates();
    this.getDownlineReps();
    this.getCommission(this.month, this.year);
  },
  watch: {
    downlineAgentId(value) {
      this.getCommission(this.month, this.year);
    },
  },
};
</script>

<style scoped>
.downline-rep-select {
  line-height: 10px;
  min-width: 250px !important;
}
.clear-search {
  line-height: 3px;
  font-size: 14px;
  font-weight: 500;
  height: 32px !important;
}
</style>
