var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.commission.total && _vm.type == 'other' || _vm.type != 'other' ? _c('li', [_c('div', {
    staticClass: "d-flex"
  }, [_c('h3', [_vm._v(_vm._s(_vm.commission.title))]), _vm.type == 'permium' ? [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "action-icon",
    attrs: {
      "href": "javascript:void(0)",
      "title": "Download PDF"
    },
    on: {
      "click": function click($event) {
        return _vm.downloadReport(_vm.commission.pdf);
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-file-pdf"
  })]), _c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "action-icon",
    attrs: {
      "href": "javascript:void(0)",
      "title": "Download CSV"
    },
    on: {
      "click": function click($event) {
        return _vm.downloadReport(_vm.commission.csv);
      }
    }
  }, [_c('i', {
    staticClass: "fas fa-file-csv csv"
  })])] : _vm._e()], 2), _c('ul', {
    staticClass: "commission-status"
  }, [_vm.commission.sub_comm ? _vm._l(_vm.commission.sub_comm, function (d, i) {
    return _c('li', {
      key: i,
      staticClass: "paid2"
    }, [d.amount ? [_vm._v(" " + _vm._s(d.title) + " "), _c('strong', [_vm._v(" $" + _vm._s(d.amount) + " ")]), parseFloat(d.amount.replace(/,/g, '')) > 0 && _vm.type == 'other' ? [_c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "action-icon",
      attrs: {
        "href": "javascript:void(0)",
        "title": "Download PDF"
      },
      on: {
        "click": function click($event) {
          return _vm.downloadReport(d.pdf);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-file-pdf"
    })]), _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "action-icon",
      attrs: {
        "href": "javascript:void(0)",
        "title": "Download CSV"
      },
      on: {
        "click": function click($event) {
          return _vm.downloadReport(d.csv);
        }
      }
    }, [_c('i', {
      staticClass: "fas fa-file-csv csv"
    })])] : _vm._e()] : _vm._e()], 2);
  }) : _vm._e(), _c('li', {
    staticClass: "cs-sub-title"
  }, [_vm.commission.total ? _c('strong', [_vm._v(" Total: "), _c('span', {
    staticClass: "paid"
  }, [_vm._v(" $" + _vm._s(_vm.commission.total) + " ")])]) : _vm._e(), _c('ul', [_c('li', {
    staticClass: "paid"
  }, [_c('strong', [_vm._v("Paid: ")]), _vm._v(" $" + _vm._s(_vm.commission.paid) + " ")]), _c('li', {
    staticClass: "unpaid"
  }, [_c('strong', [_vm._v("Unpaid: ")]), _vm._v(" $" + _vm._s(_vm.commission.unpaid) + " ")]), _c('li', {
    staticClass: "process"
  }, [_c('strong', [_vm._v("Process: ")]), _vm._v(" $" + _vm._s(_vm.commission.process) + " ")])])])], 2)]) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }