<template>
  <tbody>
    <tr v-if="!rows.records || rows.records.length < 1">
      <td colspan="7" align="center">No records found.</td>
    </tr>
    <tr v-for="(row, index) in rows.records" :key="index">
      <td>
        <span class="month-year">{{ row.date }}</span>
      </td>
      <td>
        <span class="direct-comm">${{ row.directcommission }}</span>
      </td>
      <td>
        <span class="downline-comm">${{ row.downlinecommission }}</span>
      </td>
      <td>
        <span class="total-comm">${{ row.totalcommission }}</span>
      </td>
      <td>
        <span class="total-commissions-comm"
          >${{ row.totalcommission_paid }}</span
        >
      </td>
      <td>
        <span class="commission-status">{{
          row.payment_status.toUpperCase()
        }}</span>
        <span
          class="date-info"
          v-if="row.payment_status.toLowerCase() == 'paid'"
          >Payment Date :
          {{
            row.payment_complete_date ? row.payment_complete_date : "N/A"
          }}</span
        >
        <span
          class="date-info"
          v-if="row.payment_status.toLowerCase() == 'in process'"
          >Processing Date :
          {{
            row.payment_process_date ? row.payment_process_date : "N/A"
          }}</span
        >
      </td>
      <td>
        <div class="action-icons">
          <a
            :href="row.pdf_link"
            v-b-tooltip.hover
            title="Download PDF"
            download
            target="_blank"
          >
            <i class="fas fa-file-pdf"></i>
          </a>
          <a
            :href="row.csv_link"
            v-b-tooltip.hover
            title="Download CSV"
            download
            target="_blank"
          >
            <i class="fas fa-file-csv" />
          </a>
        </div>
      </td>
    </tr>
  </tbody>
</template>
<script>
export default {
  name: "CommissionTable",
  props: {
    rows: {
      required: true,
    },
  },
};
</script>

<style scoped>
.date-info {
  display: block;
  font-size: 13px;
}
</style>
