var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('tbody', [!_vm.rows.records || _vm.rows.records.length < 1 ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "7",
      "align": "center"
    }
  }, [_vm._v("No records found.")])]) : _vm._e(), _vm._l(_vm.rows.records, function (row, index) {
    return _c('tr', {
      key: index
    }, [_c('td', [_c('span', {
      staticClass: "month-year"
    }, [_vm._v(_vm._s(row.date))])]), _c('td', [_c('span', {
      staticClass: "direct-comm"
    }, [_vm._v("$" + _vm._s(row.directcommission))])]), _c('td', [_c('span', {
      staticClass: "downline-comm"
    }, [_vm._v("$" + _vm._s(row.downlinecommission))])]), _c('td', [_c('span', {
      staticClass: "total-comm"
    }, [_vm._v("$" + _vm._s(row.totalcommission))])]), _c('td', [_c('span', {
      staticClass: "total-commissions-comm"
    }, [_vm._v("$" + _vm._s(row.totalcommission_paid))])]), _c('td', [_c('span', {
      staticClass: "commission-status"
    }, [_vm._v(_vm._s(row.payment_status.toUpperCase()))]), row.payment_status.toLowerCase() == 'paid' ? _c('span', {
      staticClass: "date-info"
    }, [_vm._v("Payment Date : " + _vm._s(row.payment_complete_date ? row.payment_complete_date : "N/A"))]) : _vm._e(), row.payment_status.toLowerCase() == 'in process' ? _c('span', {
      staticClass: "date-info"
    }, [_vm._v("Processing Date : " + _vm._s(row.payment_process_date ? row.payment_process_date : "N/A"))]) : _vm._e()]), _c('td', [_c('div', {
      staticClass: "action-icons"
    }, [_c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "href": row.pdf_link,
        "title": "Download PDF",
        "download": "",
        "target": "_blank"
      }
    }, [_c('i', {
      staticClass: "fas fa-file-pdf"
    })]), _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "href": row.csv_link,
        "title": "Download CSV",
        "download": "",
        "target": "_blank"
      }
    }, [_c('i', {
      staticClass: "fas fa-file-csv"
    })])])])]);
  })], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }